@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {text-align: center;}
body{overflow-x: hidden;font-size: 16px;color: #04151F; font-family: 'Open Sans', sans-serif  !important;}
p{font-size: 16px;}
.App-logo {height: 40vmin;pointer-events: none}
.loader-container {align-items: center;display: flex;height: 100vh;justify-content: center;width: 100vw;text-align: center;line-height: 1.6rem; }
.loader-container .loader-container-inner {display: flex;align-items: center;flex-direction: column;padding: 0 1.5rem;}
.loader-container small {margin: 10px 0 0;display: block;color: #868e96;font-weight: normal; }
.loader-container h3 {text-align: center;display: block; }
.h3, h3 {font-size: 1.5rem;}
h4{font-size: 20px;}
:focus, button:focus{outline: none !important;}
h1, h1 {font-size: 32px;}
h2{font-size: 24px;}
h5{font-size: 18px;}
.download_opt_btn {width: 100%;text-align: left;position: relative; margin-top: 35px;}
.download_opt_btn img{width: 150px  !important; margin-right:15px  !important;}
.main_title b{font-weight: 600  ;}
/* login page css */
.videoBack{position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;object-fit: fill;}
.login_section .container-fluid{position:  relative; z-index: 3   ;}
.logo_header{padding: 10px;}
.set_bg_login{background-image: url(/static/media/bg_img_tour-min.385255be.png);background-position: center;background-size: cover;}
.set_bg_login .image_section_set{margin-top: 10%;text-align: center;position: relative;}
.set_bg_login .set_img {top: 2%;left: 30%;width: 225px;}
.shadow_img{position: absolute;right: 0;width:100%;height: 100%;top: 0;object-fit: cover;z-index: 2;opacity: 0.6;}
.login_section .error_show{color: #fff; font-weight: 700;}
.dropdown-item {font-size: 16px;}
.mb-30{margin-bottom: 30px;}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {background-color: #282c34;min-height: 100vh;display: flex;flex-direction: column;align-items: center;justify-content: center;font-size: calc(10px + 2vmin);color: white;}
.App-link {color: #61dafb;}
.set_bg_login .input-group-text {width: 45px;text-align: center;margin: auto;}
.heading_header{color: red;}
.header_section {box-shadow: 0px 4px 12px 3px #dddddd9e;}
.header_sticky { position: fixed; top: 0; width: 100%; background: #fff;z-index: 10;box-shadow: 0px 1px 8px #9e9e9e;}
.navbar-expand-md .navbar-nav .nav-link{font-size: 14px;}
.navbar {padding-top: 10px;}
footer p, footer a{margin-bottom: 3px;font-size: 12px;color: #fff;}
footer a:hover{color: #ff8548;}
.page-footer .form-control{height: 45px;line-height: 45px;}
.page-footer h4{ margin-bottom: 40px;font-weight: 600; position: relative;}
.btn.btn-sm.subscribe_btn, .orange_btn.btn-sm, .orange_btn.btn-sm.form-control:focus {background: #e27741;border-color:#FF8548;color: #fff;font-size: 16px;font-weight: 400;border-radius: 0;padding: 8px 30px;}
.green_btn.btn-sm, .green_btn.btn-sm.form-control:focus {background: #0d8282;border-color:#0d8282;color: #fff;font-size: 18px;font-weight: 600;border-radius: 0;padding: 10px 50px;}
.login_section .orange_btn.btn-sm, .set_bg_login .orange_btn.btn-sm{line-height: 12px;}
.mini-text{font-size: 12px;}
.orange_text{color: #ff8548 !important;}
.green_text{color: #389594 !important;}
.footer_link {display: inline;padding-left: 0;}
.footer_link li{display: inline-block;margin: 0px 10px;font-size: 12px;}
.copyright_text {border-top: 1px solid #EAEAEA;background: #212121;padding: 10px 30px;}
.copyright_text p{margin-bottom: 0;margin-top: 4px;}
footer h4{position: relative;}
footer h4.after_effect:after{content: "";width: 35px;height: 2px;background: #22CBC7;position: absolute;left: .0;top: 40px;transition: all .5s ease;}
footer h4.after_effect:hover:after{width: 50%;}
.footer_link li:first-child{margin-left: 0;}
.form-control-sm {padding: 20px !important;border-radius: 0 !important;}
ul.footer_social_links li {display: inline-block;padding: 0 10px;color: #ff8548;}
.map_text{margin-bottom: 10px;}
ul.footer_social_links li:first-child{padding-left: 0;}
ul.footer_social_links {display: block;padding-left: 0;}
.call-contact{transform: rotate(90deg);}
.banner_section {width: 100%;position: relative;height: 500px;}
.main_home_banner{background-image: url(/static/media/tour-banner.74fae618.jpg);background-position: center;background-repeat: no-repeat;background-size: 100% 100%; background-color: #F2F2F2;}
.bottm_dash_border{border-bottom: 4px dashed #FF8548; text-transform: capitalize;}
footer .input-group{border:0;}
.banner_section img{width: 100%;height: 100%;object-fit: cover;/* opacity:0.3; */}
.banner_title{color: #fff;font-weight: 700;text-transform: capitalize;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);text-align: center;font-size: 30px;line-height: 44px;}
.main_home_banner .banner_title{top:40%;}
.sub_title_banner{color: #fff;font-weight: 700;text-transform: capitalize;position: absolute;top: 70%;left: 50%;transform: translate(-50%, -50%);text-align: center;}
.page_section{padding: 30px;position: relative;   }
img.phone_img {position: relative;z-index: 1;}
img.set_img {z-index: 0;}
.image_section_set {margin: auto;text-align: right;margin-top: -23%;}
.main_title {font-size: 26px;margin-bottom: 25px;font-weight: 600;}
.list_why h4{color: #262626;font-size: 18px;margin-bottom: 10px;}
.set_div_center h4{margin-bottom: 15px;}
.list_why li{margin-bottom: 10px;list-style: none;position: relative;}
.list_why.list_of_tours li{margin-bottom: 20px;}
.list_why_icon {position: absolute;left: -30px;top: 3px;color: #FB7F5A;border: 2px solid #FB7F5A;border-radius: 50%;padding: 3.5px;font-size: 16px;width: 20px !important;height: 20px;}
.set_div_center{margin: 50px 0;}
.white_text{color: #fff !important;}
.package_section{background-image: url(/static/media/package_bg-min.6b102eb5.png);background-position: center;background-size: 100% 100%;position: relative;}
.package_section img{opacity: 0.3;}
.tour_guide_section{background-image: url(/static/media/bg_img_tour.6c16da25.png);background-position: center;background-size: 100% 100%;position: relative;padding: 50px 0 20px;}
.tour_guide_section::before{content: "";width: 100%;height: 100%;top: 0;left: 0;background-color: #2626268c;}
.city_img{width: 100%;height: 300px;object-fit: cover;}
.city_img_bind{width: 100%; height: 100%; position: relative;}
.city_title {color: #fff;position: absolute;top: 0;left: 0;font-size: 18px;font-weight: 700;text-transform: capitalize;padding: 15px;width: 100%;background: linear-gradient(180deg, rgba(2,0,36,0.32816876750700286) 0%, rgba(2,0,36,0.16290266106442575) 35%, rgba(2,0,36,0.05926120448179273) 100%);}
.owl-dots{display: none;}
.tour_details_desc_data h2 b{font-weight: 600;}
.mb_30{margin-bottom: 30px;}
footer {background: #212121;padding: 25px 0 0;}
.tour_section_slider .item{position: relative;}
.tour_section_slider .owl-theme .owl-nav {text-align: left;}
.owl-nav button {background: #22CBC7 !important; color: #fff !important;margin-right: 6px !important;border: 1px solid #22CBC7 !important;font-size: 10px !important;height: 45px;width: 45px;line-height: 10px !important;}
.owl-nav span {height: 25px;line-height: 15px;font-size: 44px;}
.tour_section_slider .item:before{content: "";width: 100%;height: 100%;top: 0;left: 0;background-color: #212121;}
.tour_section_slider .item p{position: absolute;width: 100%;bottom: 0;background: linear-gradient(0deg, rgba(2,0,36,0.3701855742296919) 0%, rgba(2,0,36,0.2189250700280112) 35%, rgba(2,0,36,0.11808473389355745) 100%); margin-bottom: 0;padding: 5px 5px 7px;}
.icon_set_volumn {background: #22CBC7;border-radius: 50%;width: 40px;height: 40px;line-height: 35px;color: #fff;margin: auto;border: 2px solid #fff;font-size: 14px;}
.img_text {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.package_box {width: 90%;margin: auto;position: relative;height: 450px;background: #E2EAF0;box-shadow: 20px 13px 30px #5050501A;}
.pacakge_left_img {width: 40%;float: left;}
.package_right_detail {width: 60%;float: left;text-align: left;padding: 30px;}
.package_list_imgs {padding-left: 0;display: inline;}
.pacakge_left_img img{height: 450px;object-fit: cover;}
.package_list_imgs li {display: inline-block;width: 80px;margin: 5px;height: 70px;float: left;}
.package_list_imgs li p{margin-bottom: 0; height: 70px;}
.package_list_imgs li img{height: 100%;object-fit: cover;}
.more_package_imgs {background: #a4e0e3;color: #333;text-align: center;}
.more_package_imgs p{font-size: 20px;margin-bottom: 0;padding: 15px 0;}
.package_section_slider .owl-nav button:first-child {position: absolute;top: 50%;left: -20px;}
.walks_section div{position: relative;}
.image_title_package {position: absolute;bottom: -15px;left: 0px;  font-weight: 700;text-transform: capitalize;padding: 15px;width: 100%;background: linear-gradient(0deg, rgba(2,0,36,0.3701855742296919) 0%, rgba(2,0,36,0.2189250700280112) 35%, rgba(2,0,36,0.11808473389355745) 100%);}
.package_section_slider  .owl-nav button:last-child{position: absolute;top: 50%;right: -25px;}
.package_slider_section{margin-top: -9%;}
.input-group-text {background-color: #fff;border: 1px solid #fff;}
.form-control, .input-group-text{border-radius: 0;height: 40px;line-height: 40px;font-size: 18px;}
.input-group-text{width: 40px; text-align: center;}
.input-group .form-control{border: 0;}
.input-group{background: #fff;border: 1px solid #dcdcdc;}
.form-control:focus {color: #495057;background-color: #fff;border-color: #fff;outline: 0;box-shadow: none;}
button.btn.btn-border-white {border: 1px solid #fff;color: #fff;padding: 5px 15px;border-radius: 0.25rem; transition: all .3s linear;}
button.btn.btn-border-white.inner_page_back:hover {background: #fff;color: #212529;}
::-webkit-input-placeholder{font-size: 18px;}
:-ms-input-placeholder{font-size: 18px;}
::-ms-input-placeholder{font-size: 18px;}
::placeholder{font-size: 18px;}
.box_auth{width: 60%;margin: auto;}
.set_image_box{position: absolute;width: 100%;text-align: center;margin: auto;top: 2%;}
.audiio_tour_section .set_image_box{top: -28%;left: 0;}
.audiio_tour_section .image_section_set{ text-align: center;}
.accordian_package_title{position: absolute;top: 10px;left: 10px;width: 100%;font-size: 18px;}
.MuiAccordionSummary-content{margin: 0  !important;}
.MuiAccordion-root {border: 1px solid #389594;}
.MuiPaper-root  .acc_Tour_img {min-height: 55px;max-height: 55px;}
.MuiPaper-root.Mui-expanded  .acc_Tour_img{max-height: 100%;}
.MuiAccordionSummary-root{padding: 0 !important;}
.package_section .banner_title{letter-spacing: 5px;}
.package_section .sub_title_banner{font-weight: 400;top: 60%;font-size: 20px;}
.btn_section_explore{clear: both;padding-top: 50px;}
.itemCityList{height: 300px}
.accordian_package_title{position: absolute;top: 10px;left: 10px;width: 100%;font-size: 18px;}
.MuiAccordionSummary-content{margin: 0  !important;  height: 48px;}
.MuiAccordion-root .MuiAccordionSummary-root {background: #389594 !important;margin-bottom: 15px;color: #fff !important;margin-bottom: 0 !important;}
.MuiCollapse-container.MuiCollapse-entered {padding: 15px 5px;}

.MuiPaper-root  .acc_Tour_img {min-height: 48px;max-height: 48px;}
.MuiPaper-root.Mui-expanded  .acc_Tour_img{max-height: 100%;}
.MuiAccordionSummary-root{padding: 0 !important;}
.package_section .banner_title{letter-spacing: 5px; top: 35%; font-size: 28px;}        
.package_section .sub_title_banner{font-weight: 400;top: 60%;font-size: 20px;}
.btn_section_explore{clear: both;padding-top: 50px; position: absolute;right: 25px;bottom: 25px;}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;}
.banner-title-section{margin-top: -380px;margin-bottom: 170px;}
.banner-title-section h2{font-weight: 600; color:#04151F;    line-height: 34px;}
.tour_section_slider .owl-carousel .owl-item img.audio_loader{height: 30px !important;}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* about us page css :: start */
.about_mission{margin: 0 0;text-align: justify;}
.about-img img{width: 100%;margin-top: -190px;}
.contact_abt_section{background-image: url(/static/media/bg_img_tour-min.385255be.png);background-position: center;background-size: cover;position: relative;height: 350px;}
.contact_abt_section .main_title{letter-spacing: 5px;}
.contact_abt_section p{font-weight: 400;line-height: 26px;font-size: 20px;}
.conatct_box {background: #fff;box-shadow: 0 0 16px #00000029;padding: 30px;margin-top: -150px;}
/* about us page css :: End */

/* partner page css :: start */
.pl-10{padding-left: 10px;}
.banner_main_title.with_sub_title {font-weight: 600;text-transform: capitalize;}
.write_us_section, .gradiant_bg_blue{background: transparent linear-gradient(180deg, #27EEEA 0%, #28E0DD 100%) 0% 0% no-repeat;}
.why_partner_section{margin-top: -100px;}
.middle_box {margin-top: -15px;box-shadow: 0 8px 16px #00000029;z-index: 2;min-height: 100% !important;max-height: 400px;}
.why_partner_section h6{font-weight: 700;margin-bottom: 15px;padding:15px;}
.partner_images img{width: 100%; margin-bottom: 10px;}
.box_gradiant {padding: 20px 20px 0;}
.box_gradiant ul{padding-left: 15px;    transition: all 3s linear;}
.gradiant_bg_blue{padding:0;     min-height: 410px      ;}
.middle_box button{width: 100%;}
.box_gradiant ul li {font-size: 14px;margin-bottom: 15px;}
.opacity_6{opacity: 0.6;}
.tab_section {width: 100%;}
.left_icon {width: 20%;float: left;    margin-right: 5%;}
.icon_box {width: 100%;position: relative;}
.right_desc{width: 75%; float: left;}
.right_desc p{min-height: 180px;}
.banner_data {margin-top: -390px;color: #fff;padding: 0 30px;    margin-left: 40%;}
.how_it_section p {width: 70%;margin: auto;min-height: 89px;}
.main_title.with_sub_title{margin-bottom: 10px;}
.why_choose_section  p{width: 70%; margin: auto;}
.MuiTabs-indicator {display: none !important;}
.MuiTab-textColorInherit.Mui-selected {opacity: 1;background: #107472;color: #fff;}
.MuiAppBar-colorPrimary {border-bottom: 0px solid #107472!important;color: #04151f!important;background-color: transparent !important;box-shadow: none !important;}
.MuiTabs-root button:focus{outline: none !important;}
.MuiTab-root{font-size: 16px !important;font-family: 'Open Sans', sans-serif  !important;font-weight: 600 !important;}
.partner-slider .owl-nav{position: absolute; top:25%; width: 100%;}
.partner-slider .owl-nav button:first-child{    position: absolute;left: -60px;}
.partner-slider .owl-nav button:last-child{    position: absolute;right: -60px;}
.MuiTabs-flexContainer {display: block;margin: auto;text-align: center;}
.MuiTabs-flexContainer {display: block !important;}
.partner-slider{margin: auto;}
.owl-carousel .owl-stage {display: flex;align-items: center;}
/* partner page css :: end */

/* city detail css :: start */
.inner_page_back{position: relative; z-index: 8;margin-left: 15px; margin-top: -85%;}
/* city detail css :: end */

/* tour list css :: start */
.load_more_btn{margin-top: 25px;}
.search_section{display: inline-flex;padding-left: 0;list-style: none;}
.search_section li{vertical-align: middle;margin-left: 7px;line-height: 27px;}
.search_section li a{color: #04151F;font-weight: 500;}
.search_section .dropdown-menu.show{padding:0 ;}
.serach_tours_section{padding-bottom: 0;}
.search_input_tour{ width: 350px !important;}
.tour_section_slider .owl-carousel .owl-item img{height: 300px !important; object-fit: cover;}

/* tour list css :: end */
.navbar{width: 100%;}

/* tour detail css:: start */
.price_box {width: 300px;box-shadow: 0px 8px 8px #00000029;background: #fff;padding: 20px;text-align: center; z-index: 5; position: relative;}
.tour_details_desc{margin-top: -80px;}
.tour_details_desc_data{margin-top: -100px;}
.price_text {color: #FF8548;font-weight: 700;}
.like_option {border: 1px solid #22CBC7;padding: 8px;color: #22CBC7;background: #eee;font-size: 42px;margin-right: 15px;vertical-align: middle;cursor: pointer;}
.orange_link_text {color: #ff8548;border: 1px solid #ff8548;font-weight: 600;padding: 5px 5px 8px 5px;}
.orange_link_text img{width: 25px;margin-right: 10px;vertical-align: middle;}
.price_section_tour{padding-bottom: 0;}
.purchase_opt{margin-bottom: 30px;}
.map_section img{width: 100%;}
.mt_20{margin-top: 20px;}
/* .tour_detail_banner .inner_page_back {position: absolute; top: -550px;z-index: 8;left: 20px;} */

/* package details css : start */
.package_detail_section{margin-top: -40px;}
.package_detail_section h2{font-weight: 600;}
.package_slider img{height: 500px;object-fit: cover;}
.package_slider{z-index: 0;}
.package_slider .owl-dots{display: block;position: absolute;width: 100%;bottom: 0;}
.bg-light {background-color: transparent !important;}
/* package detail css :: end */

/* purcahse page css :: start */
.back_option_page{border: 1px solid #262626; color: #262626; margin-bottom: 15px;}
.purchase_city_slider h1 {position: absolute;bottom: 30px;left: 20px;}
.purchase_box {box-shadow: 0px 8px 16px #00000029;padding: 20px;border: 1px solid #eee;margin-bottom: 25px;}
.purchase_form label{margin-left: 5px;}
.purchase_box h3{margin-bottom: 20px;}
.purchase_form .btn-sm{font-size: 16px; line-height: 16px;}
.purchase_city_slider .owl-dots{display: block; position: absolute; bottom: 10px; width: 100%; text-align: center;}
.radio {margin: 0.5rem 0; }
.MuiIconButton-root.MuiCheckbox-colorSecondary {padding: 0 5px 0 0;}
.radio input[type="radio"] {position: absolute;opacity: 0;}
.radio .radio-label:before {content: '';background: #f4f4f4;border-radius: 100%;border: 1px solid #b4b4b4;display: inline-block;width: 15px;height: 15px;position: relative;top: 5.2px;margin-right: 15px;vertical-align: top;cursor: pointer;text-align: center;transition: all 250ms ease;}
.radio input[type="radio"]:checked + .radio-label:before {background-color: #FF8548; border: 1px solid #FF8548;}
.bg_light_orange{background-color: #FFD7C3;}
form.purchase_form span.input-group-text {font-size: 16px;opacity: .5;}
.radio label{cursor: pointer;}
.MuiCheckbox-colorSecondary.Mui-checked {color: #ff8548 !important;}
.PrivateSwitchBase-root-1 {padding-left: 0 !important;}
.third_party_pay .btn{line-height: 22px !important;}
/* purchase page css :: end */

/* faqs page css :: start */
.faq_section p{display: block;width: 100%; padding-right: 15px;}
.shadow_div_set {position: absolute;background: #3333337a;TOP: 0;left: 0;width: 100%;height: 100%;}
/* faqs page css :: end */

/* buy tours page css :: end */
.tour_details_show img{width: 255px;height: 300px;object-fit: cover;float: left;margin-right: 19px;}
.left_side_show{    width: 75%;float: left;}
.purchase_right_side{width: 25%;float: left;border-left: 1px solid #bebebe;padding-left: 15px;}
.tour_details_show{width: 100%;}
.left_side_show span{cursor: pointer;}
.tour_purchase_promocode .btn{float: right;margin: 15px 0;}
.arrow_icon{position: absolute;right: 20px;top: 5px;}
.acc_tours_buy .accordian_package_title {position: relative;top: 10px;left: 0px; margin: 0;width: 100%;  font-size: 18px;padding: 0 15px; transition: all .2s linear; line-height: 1.5;}
.acc_tour_buy .MuiCollapse-container {height: 0;overflow: hidden;transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;background: #fff;padding-top: 10px;border: 1px solid #eeee;border-top: 0;}
.dropdown-item.active, .dropdown-item:active {color: #fff;text-decoration: none;background-color: #0e8686;}
.MuiAccordionSummary-root.Mui-expanded{min-height: 48px !important;}
.MuiPaper-root.MuiAccordion-root {margin-bottom: 15px;}
/* buy tour css :: end */

/* My profile css :: start */
.update_profile_form .btn{line-height: 2px !important;}
form.update_profile_form {border: 1px solid #eee;padding: 30px 30px 10px 30px;box-shadow: 0px 3px 4px 5px #e1e1e1;margin-bottom: 15px;margin-top: 15px;}
/* My profile css :: end */

/* success_page css :: end */
.success_page img{cursor: pointer; transition: all .2s ease;}
.success_page h1{    font-size: 2.5rem !important; line-height: 56px;}
.success_page a:hover img{transform: scale(1.02,1.02);}
/* success_page css :: end */

/* Free Tour css :: start */
.light-bg{background-color: #f1f1f1;}
.banner-title-section h2, .banner_main_title.with_sub_title {font-size: 28px;}
/* Free Tour Css :: end */

/* sucess page css :: start */
.paySuccess_page {position: fixed;width: 100%;top: 50%;left: 50%;transform: translate(-50%, -50%);}
/* sucess page css :: end*/

/* 404 css :: start */
.error-title{font-size: 50px;font-weight: 700; }
.not-found-section img{transition: all .5s linear; -webkit-animation: roundAnim 1s  linear infinite; animation: roundAnim 1s  linear infinite;}
@-webkit-keyframes roundAnim {
  0%{
    transform: scale(.85) translate(10px,10px);
  }
  25%{
    transform: scale(.85) translate(30px,0px);
  }
  50%{
    transform: scale(.85) translate(30px,30px);
  }
  75%{
    transform: scale(.85) translate(00px,30px);
  }
  100%{
    transform: scale(.85) translate(10px,10px);
  }
}
@keyframes roundAnim {
  0%{
    transform: scale(.85) translate(10px,10px);
  }
  25%{
    transform: scale(.85) translate(30px,0px);
  }
  50%{
    transform: scale(.85) translate(30px,30px);
  }
  75%{
    transform: scale(.85) translate(00px,30px);
  }
  100%{
    transform: scale(.85) translate(10px,10px);
  }
}
/* 404 css :: end */

/* media query starts here */
@media only screen and (min-width: 1367px) {
  .tour_details_desc {margin-top: -220px;}
  .tour_details_desc.freetourBox {margin-top: -150px;}
  .banner-title-section h2, .banner_main_title.with_sub_title {font-size: 28px;}
}
@media only screen and (max-width: 1366px) {
  ::-webkit-input-placeholder {font-size: 14px !important;}
  :-ms-input-placeholder {font-size: 14px !important;}
  ::-ms-input-placeholder {font-size: 14px !important;}
  body, p, .form-control, ::placeholder, .MuiTab-root {font-size: 14px !important;}
  .dropdown-item {font-size: 14px;}
  .list_why h4 {margin-bottom: 20px;}
  .banner_title{font-size: 32px;}
  .main_home_banner .banner_title {top: 40%;}
  .main_home_banner .download_opt_btn {top: 74%;}
  .banner_section {height: 400px;}
  .package_slider img{height: 400px;}
  .banner_data {margin-top: -300px; }
  .work_img{width: 220px; }
  .set_div_center {margin: 50px 0;}
  .right_desc p {min-height: 145px !important;}
  .banner-title-section {margin-top: -330px;margin-bottom: 130px;}
}
@media only screen and (max-width: 1366px) and (min-width: 1200px) {
  .inner_page_back{margin-top: -65%;}
  .tour_details_desc {margin-top: -190px;}
  .tour_details_desc.freetourBox {margin-top: -110px;}
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .set_image_box {left: 4%;}
  .audiio_tour_section .set_image_box {top: -14%;left: 4%;}
  .tour_details{margin-top: -90px;}
  .inner_page_back { margin-top: -105%;}
  .right_desc{margin-bottom: 30px;}
  .right_desc p{ min-height: auto !important;}
  .main_home_banner .banner_title {font-size: 26px;line-height: 1.5;}
  .banner_data {margin-top: -340px!important;padding: 0 15px;}
  .banner-title-section {margin-top: -330px;margin-bottom: 100px;}
  .tour_details_desc {margin-top: -190px;}
  .tour_details_desc.freetourBox {margin-top: -110px;}
  .banner-title-section h2, .banner_main_title.with_sub_title {font-size: 24px;}
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .audiio_tour_section .set_image_box {top: -21%;}
  .banner-title-section {margin-top: -330px;margin-bottom: 100px;}
  .inner_page_back{margin-top: -80%;}
  .tour_details_desc {margin-top: -180px;}
  .tour_details_desc.freetourBox {margin-top: -110px;}
  .banner-title-section h2, .banner_main_title.with_sub_title {font-size: 26px;}
}
@media only screen and (max-width: 767px) and (min-width: 577px) {
  .tour_details {margin-top: -25px;}
}
@media only screen and (max-width: 576px) and (min-width: 518px) {
  .tour_details {margin-top: -45px;}
}
@media only screen and (max-width: 517px) and (min-width: 437px) {
  .tour_details {margin-top: -85px;}
  .inner_page_back { margin-top: -140%;}
}
@media only screen and (max-width: 436px) and (min-width: 425px) {
  .tour_details {margin-top: -105px;}
  .inner_page_back { margin-top: -140%;}
}
@media only screen and (max-width: 426px) and (min-width: 358px) {
  .tour_details {margin-top: -105px;}
  .inner_page_back { margin-top: -140%;}
  .main_home_banner .banner_title{font-size: 20px !important; line-height: 30px;}
}
@media only screen and (max-width: 357px) and (min-width: 200px) {
  .tour_details {margin-top: -160px;}
  .inner_page_back { margin-top: -140%;}
  .banner_data {margin-top: -285px;}
  .main_home_banner .banner_title{font-size: 20px !important; line-height: 30px;}
}
@media only screen and (max-width: 767px) {
  .set_mobile_center{text-align: center !important;}
  .back_page_option{margin-top: 1rem;}
  .mytour_app_img{width: 100%;}
  .faq_section p{padding-right: 0;}
  .partner-slider .owl-carousel .owl-item img{width: 70%;margin: auto;}
  .download_option img:first-child{margin-bottom: 15px; }
  .download_option a{margin-right:15px !important}
  .videoBack{height: 380px;}
  .reset_section .videoBack{height: 340px;}
  .login_section.reset_section .shadow_img{height: 340px;}
  .login_section .row{margin-bottom: 0 !important;}
  .inner_page_back{margin-top: -245px;}
  .mb_30 {margin-bottom: 15px;}
  .success_page h1{font-size: 18px !important; line-height: 30px;}
  .h1, h1 {font-size: 1.3rem;}
  .tour_details_desc_data {margin-top: -10px;}
  .box_auth {width: 100%;}
  .tour_section_slider .owl-carousel .owl-item img{height: 200px !important;}
  .itemCityList {height: 200px;}
  .package_slider_desktop{display: none;}
  .set_bg_login .set_img {width: 160px;}
  img.phone_img{ width: 250px;}
  .page_section {padding: 10px 0;}
  .package_slider_section {margin-top: -4%;}
  .set_bg_login {background-position: top center; background-size: 100% 48%; background-repeat: no-repeat;}
  .shadow_img{ width: 100%; height: 48%;}
  .responsive_setdiv h3{  margin-top: 30px; font-size: 20px;}
  .responsive_setdiv img{margin-bottom: 15px;}
  .responsive_setdiv .white_text{color: #212121 !important;}
  .login_section.set_bg_login{background-size: 100% 39%;}
  .login_section .shadow_img{ height: 380px;}
  .forgot_section.set_bg_login{background-size: 100% 32%;}
  .forgot_section .shadow_img{height: 290px;}
  .forgot_section .videoBack {height: 290px;}
  .registration_Section .shadow_img{height: 530px;}
  .registration_Section .videoBack{height: 530px  ;}
  .banner_title {top: 30%;font-size: 20px;line-height: 26px;}
  .audiio_tour_section .image_section_set {margin-top: -100px;}
  .audiio_tour_section .set_image_box {    position: relative;margin-top: -368px;}
  .audiio_tour_section .set_image_box img{width: 160px;}
  .map_section div div{width: 96% !important;}
  .city_img{height: 200px;}
  .box_auth h3{font-size: 20px;}
  .sub_title_banner{top: 50%;    width: 100%;}
  .package_section.banner_section{height: 300px;}
  .pacakge_left_img, .package_right_detail{width: 100%;}
  .pacakge_left_img img{ height: 100%;}
  .package_list_imgs{display: none;}
  .package_right_detail h4 {position: absolute;top: 10px; font-size: 16px;left: 15px; color: #fff;}
  .walk_text {    display: inline-block !important;font-size: 12px;vertical-align: middle;line-height: 18px;float: right;margin-right: 25px;}
  .walks_images{height: 200px;}
  .work_img{width: 100%;}
  .about-img img{margin-bottom: 20px; margin-top:0}
  .responsive_package_section{display: block !important; margin-top: 50px;}
  .package_right_detail {padding: 10px 5px;}
  .conatct_box{margin-top: -130px;}
  .inner_banner{height: 150px;margin-bottom: 15px;}
  .banner_section {height: 150px;}
  .main_home_banner{height: 150px;}
  .inner_banner .banner_title {top: 50%;}
  /* .box_gradiant ul{display: none;} */
  .why_partner_section {margin-top: 0;}
  .gradiant_bg_blue {min-height: 120px;}
  .inner_banner.partner_banner .banner_title{  top: 70%;}
  .search_section {display: block;}
  .search_input_tour {width: 1% !important;}
  .tour_list_banner .banner_title, .faq_banner .banner_title  {top: 50%;}
  .price_box{width: 100%; right: 0; position: relative; top:0; box-shadow: none !important; padding: 5px 20px;height: auto;min-height: 55px;border: 1px solid #eee;}
  .price_box_body .price_text{float: right; margin-bottom: 0; margin-top: 6px;}
  .price_box_body p{float: left; margin-bottom: 0;}
  .price_box_body .purchase_opt {position: fixed;bottom: -30px;z-index: 2;width: 100%;left: 0;display: inline-flex !important;}
  .price_box_footer{clear: both;}
  .price_box_footer p{display: none;}
  .price_box_body {width: 100%;float: left;line-height: 3;}
  .package_slider img {height: 200px;}
  .inner_banner.package_slider img{height: 150px;}
  .package_details .tour_details_desc {margin-top: 0px;}
  .package_detail_section {margin-top: 10px;}
  .like_option{margin: 0; height: 50px; padding: 7px;}
  .purchase_opt button {width: 90%;margin: 0;}
  .list_why h4{margin-bottom: 10px;}
  h4{font-size: 18px !important;}
  .set_div_center{margin: 20px 0;}
  .package_section .banner_title {font-size: 20px;line-height: 30px;width: 100%;top: 30%; text-align: left; left:60%;}
  .package_section .sub_title_banner {top: 65%; width: 80%;text-align: left;left: 50%;}
  .tour_guide_section{padding: 30px 5px;}
  .main_title{font-size: 20px;}
  .banner_data {    margin-top: 30px; padding: 0; color: #04151F; margin-left: 0;}
  .right_desc h4{font-size: 18px;}
  .right_desc{margin-bottom: 30px;}
  .right_desc p{ min-height: auto !important;}
  .banner_main_title{font-size: 20px; position: relative;color: #04151F;}
  .main_home_banner .banner_title{font-size: 20px; width: 100%;padding: 15px; color: #04151F;}
  .audiio_tour_section .main_title{margin-top: 25px;}
  .banner_data button.mr-3{margin-right: 5px !important;}
  .btn.btn-sm.subscribe_btn, .orange_btn.btn-sm, .orange_btn.btn-sm.form-control:focus{padding: 10px; font-size: 14px;}
  .download_opt_btn img{width: 120px;}
  .banner_data{    z-index: 0;}
  .three_section{margin-top: 190px;}
  h5{font-size: 16px;}
  .how_it_section p {min-height: auto;margin-bottom: 15px;}
  .page-footer h4 {margin-bottom: 10px;}
  footer h4.after_effect:after {top: 27px;}
  .walks_section div div div div{margin: 0 10px 15px 5px;}
  .walks_section.partner-slider div div div div{margin: 0;}
  .walks_section{padding: 0;}
  .walks_section.tour_list_data{padding: 0 15px;}
  h2{font-size:22px ;}
  .partner-slider .owl-nav button:last-child{right: -30px;top: 40px;}
  .partner-slider .owl-nav button:first-child{left: -30px;top: 40px;}
  .banner-title-section {margin-top: 20px;margin-bottom: 0; padding: 0 ;}
  .banner-title-section h2{font-size: 20px;}
  .banner-title-section h2, .banner_main_title.with_sub_title{font-size: 20px;}
  .city_title{font-size: 16px;}
  .purchase_right_side {width: 100%;margin-top: 25px; border-left: 0; float: none;text-align: center;}
  .left_side_show {width: 100%;float: left; margin: auto;text-align: center;}
  .tour_details_show img {width: 150px; height: 200px;text-align: center;float: none;margin-right: 0;margin-bottom: 15px;}
  .download_opt_btn{margin-top: 20px;}
}
@media only screen and (max-width: 575px) {
  .banner_data {margin-left: 25px;  margin-top: 20px; width: 90%;}
  .three_section {margin-top: 200px;}
  .package_section .sub_title_banner {left: 45%;}
  .package_section .banner_title {left: 55%;}
}
@media only screen and (max-width: 375px) {
  .banner_data {margin-top: 3px;}
  .icon_box .left_icon img{width: 100%;}
  .download_opt_btn img {width: 120px !important;}
}
@media only screen and (max-width: 320px) {
  img.phone_img {width: 237px;}
  .audiio_tour_section .set_image_box img {width: 156px;}
  button, .btn{font-size: 14px !important;}
  .audiio_tour_section .set_image_box {margin-top: -350px;}
}
.bg-light {background-color: transparent !important;}
.app_imgs{width: 80%; margin: auto; margin-bottom: 20px;}
.number_info{margin-right: 10px;}

